import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-white.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-white.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-white.svg";

import moment, { now } from "moment";
import { Link } from "react-router-dom";
import Locale from "translations";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

export default function PackageCardwithImage(props) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const {
    image,
    name,
    departure_date_at,
    return_date_at,
    stars,
    product_uuid,
    hotelName,
    price,
    locationName,
    country,
    initialName,
    currency,
  } = props;

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  let countryName = country ? (country[locale] || country) : (locationName[locale] || locationName);

  return (
    <Link
      to={product_uuid ? `/package/${product_uuid}` : "/"}
      key={product_uuid}
    >
      <article
        className={`package-card-container ${!image ? "empty-service" : ""}`}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="package-card-details">
          <div className="price">
            <p>{webBuilder.from}</p>
            <span className="amount">
              {exchangedPrice}{" "}
              <span className="currency">
                {" "}
                {exchangeCurrency}
              </span>
            </span>
          </div>
          <div className="package-info">
            <div className="rate">
              <div className="starts mb-2">
                {[...Array(5)].map((i, index) =>
                  index < stars ? (
                    <GoldenStarIcon key={`package-rate-${index}`} />
                  ) : (
                    <StarIcon key={`package-rate-${index}`} />
                  )
                )}
              </div>
              <h5 className="font-weight-bold mb-2 text-white">{name || initialName}</h5>
            </div>
            {countryName &&
              <div className="location mb-0">
                <LocationIcon /> <span className="ms-2rem">{countryName}</span>
              </div>
            }
            <div className="date">
              <CalendarIcon />
              <span className="ms-3rem">
                {`
							${moment(departure_date_at || now()).format("DD MMM YYYY")} - 
							${moment(return_date_at || now()).format("DD MMM YYYY")}`}
              </span>
            </div>
            {hotelName ? (
              <div className="hotel">
                <Hotelcon /> <span className="ms-2rem">{hotelName}</span>
              </div>
            ) : null}
          </div>
        </div>
      </article>
    </Link>
  );
}
