import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import ErrorModal from "components/ErrorModal";
import { useSBSDispatch, useSBSState } from "context/global";
import moment from "moment";
import "moment/locale/ar";
import { Suspense, useEffect } from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import "scss/style.scss";
import Locale from "translations";
import ErrorBoundary from "./components/Errors/ErrorBoundary/ErrorBoundary";
import Loader from "./components/Loader";
import AxiosConfiguration from "./helpers/axiosConfiguration";
import Routes from "./routes";
import preLoginRoutes from "preLoginRoutes";
import DashboardLayout from "layouts/DashboardLayout";
import { WebBuilderProvider } from "context/webBuilder";
import { getAllCurrencies } from "services/webBuilder";

export default function App(props) {
  const { locale, loading } = useSBSState();
  const { isAuth } = useSBSState();
  const dispatch = useSBSDispatch();

  // const allCountries = useFetchCountries();
  Locale.setLanguage(locale);
  document.getElementsByTagName("html")[0].setAttribute("dir", locale === "ar" ? "rtl" : "ltr")
  document.getElementsByTagName("html")[0].setAttribute("lang", locale)
  moment.locale(locale || "en");
  // Axios Configurations

  useEffect(() => {

    async function getCountries() {
      return await axios.get(`${process.env.REACT_APP_LOOKUPS_ENDPOINT}/geolocation/countries`)
    }
    async function get() {

      const [countries, currencies] = await Promise.all([getCountries(), getAllCurrencies()]);
      dispatch({
        type: "getCountries",
        payload: countries.data.data,
      });
      dispatch({
        type: "getCurrencies",
        payload: currencies,
      });
    }
    get();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  AxiosConfiguration();


  console.log("fix-country-name");

  
  return (
    <>
      {loading && <Loader />}
      <ErrorModal />
      <div
        style={{
          direction: locale === "ar" ? "rtl" : "ltr",
          textAlign: locale === "ar" ? "right" : "left",
          fontFamily: locale === "ar" ? "Cairo" : "Cairo",
        }}
      >
        <ReactNotification />
        <ErrorBoundary>
          {isAuth ? (
            <Router>
              <Suspense fallback={<Loader />}>
                <DashboardLayout>{Routes}</DashboardLayout>
              </Suspense>
            </Router>
          ) : (
            <Router>
              <WebBuilderProvider>
                <Suspense fallback={<Loader />}>
                  <DashboardLayout>{preLoginRoutes}</DashboardLayout>
                </Suspense>
              </WebBuilderProvider>
            </Router>
          )}
        </ErrorBoundary>
      </div>
    </>
  );
}
