import useExchangeRate from "hooks/useExchangeRate";
import React from "react";
import { Link } from 'react-router-dom';
import Locale from "translations";

const arrowIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M23.0391 21.92L27.999 16.96L23.0391 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4 16.96H28" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default function FlightServiceCardTheme5(props) {
  const { product_uuid, name, price, flightItems, currency } = props;
  const { webBuilder } = Locale;
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  let flightDestinations = Array.isArray(flightItems) ? flightItems : [];
  const flightUrl = `/flight/${product_uuid}`;

  return (
    <Link to={flightUrl} className="flight-card">
      <h5 className="title">{name}</h5>

      <DestinationPort
        fromPortCode={flightDestinations[0]?.fromPort?.code}
        fromCountry={flightDestinations[0]?.fromCountry?.name}
        toPortCode={flightDestinations[0]?.toPort?.code}
        toCountry={flightDestinations[0]?.toCountry?.name}
      />

      <p className="price">
        <span>{webBuilder.from}</span>
        <span className="amount">{exchangedPrice}</span> {" "}
        <span className="currency">{exchangeCurrency}</span>
      </p>

    </Link>
  );
}

function DestinationPort({ fromPortCode, fromCountry, toPortCode, toCountry }) {
  return (
    <div className="destination-port">
      <p>
        <span className="port-name">({fromPortCode})</span>
        <br />
        <span className="port-country">{fromCountry}</span>
      </p>

      <span className="arrow">{arrowIcon}</span>

      <p>
        <span>({toPortCode})</span>
        <br />
        <span className="port-country">{toCountry}</span>
      </p>
    </div>
  );
}
