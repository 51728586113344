// Translate
import Locale from "translations";
// Assets
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";

// Hooks
import { Link } from "react-router-dom";
import { useSBSState } from "context/global";
import moment, { now } from "moment";
import useExchangeRate from "hooks/useExchangeRate";
// ------------------------------------------------------------------------------------------

export default function PackageCardTheme10(props) {
  const {
    image,
    name,
    // stars,
    product_uuid,
    hotelName,
    price,
    locationName,
    country,
    initialName,
    currency,
    departure_date_at,
    return_date_at,
    stars
  } = props;

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  const { webBuilder } = Locale;
  const { locale } = useSBSState();

  const packageUrl = `/package/${product_uuid}`;
  let rate = +stars && +stars > 5 ? 5 : +stars;

  let countryName = country ? (country[locale] || country) : (locationName[locale] || locationName);

  // -------- JSX Code --------
  return (
    <Link to={packageUrl} className="package-card">
      <div className={`card-img`}>
        {image ? (
          <img src={image} alt="our package" />
        ) : (
          <img
            width={"100%"}
            src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
            alt="Safa Visa"
          />
        )}
      </div>
      {/* card-caption */}
      <div className="card-content-top">
        {/* package name and location */}
        <div className="card-details">
          <h4 className="title">{name || initialName}</h4>
          <span className="rate">
            <GoldenStarIcon />
            {rate}
          </span>
        </div>
        {countryName &&
          <p className="location">
            <LocationIcon />
            <span className="mx-2">{countryName || "-"}</span>
          </p>
        }


        <div className="d-flex align-items-center">
          <Hotelcon className="me-1" />
          <span className="hotel-name">{hotelName ? hotelName : " - "}</span>
        </div>

        <div className="d-flex align-items-center">
          <CalendarIcon className="me-1" />
          <span>
            {`${moment(departure_date_at || now()).format(
              "DD MMM YYYY"
            )} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
          </span>
        </div>
      </div>



      {/* package price */}
      <div className="card-content-bottom">
        <div className="d-flex justify-content-between border-top pt-3">
          <span className="d-block mb-2">{webBuilder.from}</span>
          <p className="price">
            <span className="amount">{exchangedPrice}</span> {" "}
            <span className="currency">{exchangeCurrency}</span>
          </p>
        </div>

      </div>
    </Link>
  );
}
