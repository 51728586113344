import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import Carousel from "react-multi-carousel";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import Locale from "translations";
import { useSBSState } from "context/global";
import { Link } from "react-router-dom";
import useExchangeRate from "hooks/useExchangeRate";



export default function HotelTheme4({ items }) {

  const { locale } = useSBSState();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };
  const showArrows = items.length > responsive.desktop.items;

  return (
    <Carousel
      responsive={responsive}
      itemClass={"service-slide"}
      className="hotel-container"
      slidesToSlide={1}
      arrows={false}
      keyBoardControl={true}
      customButtonGroup={showArrows ? <ButtonGroup /> : null}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      renderArrowsWhenDisabled={true}
      infinite={true}
      rtl={locale === "ar"}
    >
      {items.map((hotel) => {
        return (
          <HotelCardTheme4  hotel={hotel} />
        );
      })}
    </Carousel>
  );
}


function HotelCardTheme4({ hotel }) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price: hotel?.price, currency: hotel?.currency });

  return (
    <div
      key={hotel?.product_uuid}
      className="card-holder"
    >
      <div className={`service-img`}>
        {hotel?.image ? (
          <img src={hotel?.image} alt="our package" />
        ) : (
          <img
            src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
            alt="Safa Visa"
          />
        )}
      </div>

      <div className="card-caption-holder">
        <h3 className="card-title mb-0">{hotel?.name}</h3>

        <div className="rate mb-2">
          {[...Array(5)].map((i, index) =>
            index < hotel?.stars ? (
              <GoldenStarIcon key={index} />
            ) : (
              <StarIcon key={index} />
            )
          )}
        </div>

        {/* Hotel Location */}
        <div className="pb-2">
          <LocationIcon className="location-icon" />
          <span className="location">{hotel?.locationName?.[locale] || hotel?.locationName} {hotel?.city ? ` - ${hotel?.city}` : null}</span>
        </div>

        {/* Card Action and Pricing */}
        <div className="card-action-holder">
          <div className="card-price">
            <p className="label">{webBuilder?.startFrom}</p>

            <div className="d-flex align-items-center">
              <p className="price">{exchangedPrice}</p>
              <p className="ms-1">
                {exchangeCurrency}
                /<span>{webBuilder?.night}</span>
              </p>
            </div>
          </div>

          <Link to={hotel?.product_uuid ? `/hotels/${hotel?.product_uuid}` : "/"} className="btn book-btn" >{webBuilder?.bookNow}</Link>
        </div>
      </div>
    </div>
  )
}
