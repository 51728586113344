import { Link } from "react-router-dom";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import CurrencySwitcher from "components/CurrencySwitcher/CurrencySwitcher";
import { ReactComponent as MenuIcon } from "assets/images/webBuilder/menu.svg";
import { useWebBuilderState } from "context/webBuilder";
import { useSBSState } from "context/global";
import { useHistory } from "react-router-dom";
import HeaderContactPhones from "./ContactPhones";

export default function HeaderThemeFour({
  nav,
  logoImage,
  handleOpenMobileNav,
  clickContactButton,
}) {
  const { locale } = useSBSState();
  const history = useHistory();
  const { hero } = useWebBuilderState();
  const header = hero?.header;

  return (
    <header className="wb-header">
      {/* logo */}
      <div className="logo">
        <Link to="/">
          <img src={logoImage} alt="Logo" />
        </Link>
      </div>

      {/* nav */}
      <div className="nav-container">
        {nav}
        <div className="d-flex-language-contact d-flex align-items-center gap-10">
          <button
            className="contact-button mr-2"
            onClick={() => {
              // check if we inside view package page when click scroll back to preview page the scroll using useeffect
              history.push({ pathname: `/`, state: header?.contactUs?.id });
              clickContactButton();
            }}
          >
            {header?.contactUs?.name?.[locale] || header?.contactUs?.name}
          </button>
          <LanguageSwitcher show={false} />
          <CurrencySwitcher show={false} />

          <HeaderContactPhones />

          <button className="nav-mobile-icon" onClick={handleOpenMobileNav}>
            <MenuIcon />
          </button>
        </div>
      </div>
    </header>
  );
}
