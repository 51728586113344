import React from 'react'
import { ReactComponent as ArrowIcon } from 'assets/images/webBuilder/chevron-right.svg'
import { useSBSState } from 'context/global';
import Locale from 'translations';

function PublicPageHeader({ service, packageData }) {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;

  return (
    <div className='public-page-header'>
      <h1 className='page-name'>{packageData?.name?.[locale]}</h1>
      <div className='bread-crumb'>
        <span className='text-capitalize'>{webBuilder[service] || service}</span>

        <ArrowIcon />
        <span>{packageData?.name?.[locale]}</span>
      </div>
    </div>
  )
}

export default PublicPageHeader