import { useSBSState } from "context/global";
import { useWebBuilderState } from "context/webBuilder";
import { logoURL } from "helpers/utils";
import { useHistory, Link, useLocation } from "react-router-dom";
import Locale from "translations";

const currentYear = new Date().getFullYear();

export default function CenteredFooter({ socialMediaIcons }) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const history = useHistory();
  const location = useLocation();

  const { footer: footerDetails, hero } = useWebBuilderState();
  const header = hero?.header;
  // const hasLogo = header?.layout?.hasLogo

  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const nav = (
    <nav className="nav-list">
      <ul className="links-list">
        <li
          onClick={() => {
            if (location.pathname !== "/") {
              history.push({
                pathname: `/`,
                state: "main-web-builder",
              });
            } else {
              scrollToSection("main-web-builder");
            }
          }}
          className={"text-white"}
        >
          <Link className></Link>
          {webBuilder.home}
        </li>

        <li
          onClick={() => {
            if (location.pathname !== "/") {
              history.push({
                pathname: `/`,
                state: "contact-us-section",
              });
            } else {
              scrollToSection("contact-us-section");
            }
          }}
          className={"text-white"}
        >
          {webBuilder.getHelp}
        </li>

        <li
          onClick={() => {
            if (location.pathname !== "/") {
              history.push({
                pathname: `/`,
                state: "our-service",
              });
            } else {
              scrollToSection("our-service");
            }
          }}
          className={"text-white"}
        >
          {webBuilder.ourServices}
        </li>
      </ul>
    </nav>
  );
  const imageSrc = logoURL(header?.logo, header?.logo?.[locale]);

  return (
    <>
      <div className="w-100">
        <div className="web-builder-footer">
          <div className="d-flex align-items-center footer-icon footer-logo justify-content-center">
            <Link to="/">
              <img
                className="footer-photo"
                src={imageSrc}
                alt="Logo"
              />
            </Link>
          </div>

          <div className="footer-links">{nav}</div>

          {/* social icons */}
          {footerDetails?.links?.filter((link) => link.visible && link.url)
            ?.length ? (
            <div className="footer-socialIcons">
              <p className="footer-text">{webBuilder.followUsOn}</p>
              <div className="d-flex align-items-center gap-10 py-2">
                {footerDetails?.links?.map((link) => {
                  if (link?.visible && link?.url) {
                    return (
                      <div key={link?.id}>
                        <a href={link?.url} target="_blank" rel="noreferrer">
                          {socialMediaIcons[link?.id]}
                        </a>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          ) : null}
          <div className="footer-copyrights align-items-center">
            <p>
              {webBuilder.allRightsReserved} {" - "}
              <a
                href="https://safasoft.com/"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                {webBuilder.safasoft}
              </a>{" "}
              © {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
